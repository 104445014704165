<template>
  <div class="tabs-con">
    <div class="content">
      <div
        :class="active == item.id ? 'tab-item active' : 'tab-item'"
        v-for="item in tabList"
        :key="item.id"
      >
        <span class="tab-name">{{ item.name }}</span>
        <span class="line"></span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TabCon",
  props: {
    active: {
      required: true,
      default: 0,
    },
    tabList: {
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.tabs-con {
  width: 100%;
  height: 0.88rem;
  overflow: hidden;
  display: flex;
  align-items: center;

  .content {
    display: flex;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .tab-item {
      min-width: 1.6rem;
      padding: 0 0.2rem;
      font-size: 0.3rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9b9b;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.active {
        color: #000;
        font-family: Helvetica;
      }

      .line {
        width: 0.26rem;
        height: 0.08rem;
        background: #ff684a;
        border-radius: 0.05rem;
        margin-top: 0.1rem;
      }
    }
  }
}
</style>