<template>
  <div class="order-list">
    <div class="tabs-box">
      <div class="content">
        <div
          :class="activeTab == item.id ? 'tab-item active' : 'tab-item'"
          v-for="item in tabList"
          :key="item.id"
          @click="changeTab(item.id)"
        >
          <span class="tab-name">{{ item.name }}</span>
          <span class="line"></span>
        </div>
      </div>
    </div>
    <div class="list-box">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="orderList && orderList.length > 0"
          v-model="loading"
          :finished="finished"
          :loading-text="loadingText"
          finished-text="没有更多了"
          @load="getDataList"
        >
          <div
            v-for="item in orderList"
            :key="item.orderId"
            class="list-item"
            @click="toOrderDetails(item.orderId)"
          >
            <div class="list-item-top">
              <div class="item-time">
                <img
                  class="item-icon"
                  src="../assets/images/fllogo.png"
                  alt=""
                />
                <span>{{ mallInfo.mallName }}</span>
              </div>
              <span class="item-status">{{
                orderStatus[item.orderStatus] || ""
              }}</span>
            </div>
            <div class="list-item-body">
              <div class="item-img-box">
                <img
                  :src="
                    item.productType == 30
                      ? require('../assets/images/bill.png')
                      : item.productImg || defaultProductImg
                  "
                  alt=""
                />
              </div>
              <div class="item-info">
                <div class="item-product">
                  <div class="product-name one-line-hidden">
                    {{ item.productName }}
                  </div>
                  <div class="product-price">
                    <span class="unit">￥</span>
                    <span class="price">{{
                      mathManage.formatMoney(item.sumOrderPrice, 2)
                    }}</span>
                  </div>
                </div>
                <div class="item-type">
                  <div class="attr-text one-line-hidden">
                    {{
                      renderAttrName(item.productSpecName, item.productAttrName)
                    }}
                  </div>
                  <span class="buynum">x{{ item.productNum }}</span>
                </div>
                <div class="service-box">
                  <div class="product-type">
                    <span class="type-name">{{
                      item.deliveryMode == 10 ? `账号直充` : "卡密兑换"
                    }}</span>
                  </div>
                  <span class="service-text" v-if="item.afterSaleStatus">{{
                    serviceStatus[item.afterSaleStatus]
                  }}</span>
                </div>
              </div>
            </div>
            <div class="item-pricebox">
              <div class="pricebox-left">
                <span>总价</span>
                <span>￥</span>
                <span
                  >{{ mathManage.formatMoney(item.sumOrderPrice, 2) }}，</span
                >
              </div>
              <div class="pricebox-center">
                <span>优惠</span>
                <span>￥</span>
                <span>{{ mathManage.formatMoney(item.couponPrice, 2) }}，</span>
              </div>
              <div class="pricebox-right">
                <span>实付款</span>
                <span>￥</span>
                <span>{{
                  mathManage.formatMoney(item.orderRealMoney, 2)
                }}</span>
              </div>
            </div>
            <div class="list-item-action">
              <span
                v-if="
                  item.orderStatus == 40 ||
                  item.orderStatus == 50 ||
                  item.orderStatus == 60
                "
                class="action-btn"
                @click="buyAgain(item, $event)"
                >再来一单</span
              >
              <span
                v-if="item.orderStatus == 10"
                @click="toPay(item.orderId, $event)"
                class="action-btn pay"
                >立即支付</span
              >
            </div>
          </div>
        </van-list>
        <div v-else class="no-list">
          <div class="no-img">
            <img src="../assets/images/no-list.png" alt="" />
          </div>
          <div class="no-tip">当前暂无订单</div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import Tabs from "../components/Tabs.vue";
import { List, PullRefresh } from "vant";
import { orderStatus, serviceStatus } from "../utils/dictionary";
import { getOrderList } from "@/api/common";
import mathManage from "@/utils/mathManage";
import { mapState } from "vuex";
export default {
  name: "OrderList",
  data() {
    return {
      mathManage,
      serviceStatus,
      activeTab: "",
      orderStatus,
      tabList: [
        {
          id: "",
          name: "全部",
        },
        {
          id: "10",
          name: "待支付",
        },
        {
          id: "20",
          name: "已支付",
        },
        {
          id: "30",
          name: "已完成",
        },
      ],
      loading: false,
      loadingText: "加载中..",
      finished: false,
      refreshing: false,
      orderList: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  components: {
    Tabs,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  computed: {
    ...mapState(["defaultProductImg", "mallInfo"]),
  },
  created() {
    document.title = "我的订单";
    this.activeTab = this.$route.query.status || "";
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    // 切换tba
    changeTab(activeTab) {
      if (this.activeTab == activeTab) return;
      this.activeTab = activeTab;
      this.$nextTick(() => {
        this.getList();
      });
    },

    getList() {
      this.pageIndex = 1;
      this.$nextTick(async () => {
        try {
          const res = await getOrderList({
            pageSize: this.pageSize,
            pageIndex: this.pageIndex,
            OrderStatus: this.activeTab,
          });
          if (res && res.code == 0) {
            this.orderList = res.data.list || [];
            if (res.data.total === this.orderList.length) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.pageIndex = 2;
            this.refreshing = false;
            this.loading = false;
          } else {
            this.$toast(res.message);
            this.loading = false;
            this.finished = true;
          }
        } catch (error) {
          this.loading = false;
          this.finished = true;
        }
      });
    },

    // 获取订单列表
    async getDataList(type) {
      try {
        const res = await getOrderList({
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          OrderStatus: this.activeTab,
        });
        if (res && res.code == 0) {
          this.orderList = [...this.orderList, ...(res.data?.list || [])];
          this.$nextTick(() => {
            if (this.orderList.length < res.data.total) {
              this.loading = false;
              this.pageIndex++;
            } else {
              this.finished = true;
              this.loading = true;
            }
          });
        } else {
          this.$toast(res.message);
          this.loading = false;
          this.finished = true;
        }
      } catch (error) {
        this.loading = false;
        this.finished = true;
      }
    },

    // 下拉刷新
    onRefresh() {
      this.getList();
    },

    renderAttrName(productSpecName, productAttrName) {
      if (!productSpecName && !productAttrName) return "";
      if (!productAttrName) return productSpecName;
      return `${productSpecName};${productAttrName}`;
    },

    // 立即支付
    toPay(orderId, e) {
      e.stopPropagation();
      this.$router.push(`/mall/cashierDesk?id=${orderId}`);
    },

    // 再来一单
    buyAgain(data, e) {
      const { productType, orderRechargeAccount, stockProductId = "" } = data;
      e.stopPropagation();
      if (productType == 30 || productType == 40) {
        this.$router.push(
          `/mall/phoneBill?tel=${orderRechargeAccount}&productType=${productType}&productId=${stockProductId}`
        );
        return;
      }
      this.$router.push(`/mall/ProductDetails?productId=${stockProductId}`);
    },

    // 去订单详情
    toOrderDetails(orderId) {
      this.$router.push(`/mall/orderDetails/${orderId}`);
    },
  },
};
</script>

<style lang="less" scoped>
.order-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .tabs-box {
    width: 100%;
    height: 0.88rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: #fff;

    .content {
      display: flex;
      padding: 0 0.24rem;
      padding-top: 0.26rem;

      .tab-item {
        width: 1.74rem;
        padding: 0 0.2rem;
        font-size: 0.3rem;
        font-family: Helvetica;
        font-weight: 400;
        color: #9b9b9b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.active {
          color: #000;
          font-family: Helvetica;
        }

        .line {
          width: 0.26rem;
          height: 0.08rem;
          background: #fff;
          border-radius: 0.05rem;
          margin-top: 0.08rem;
        }
      }
      .tab-item.active .line {
        background: #ff684a;
      }
    }
  }

  .list-box {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 0.08rem;
    padding: 0.28rem 0.36rem;
    box-sizing: border-box;

    /deep/ .van-pull-refresh {
      min-height: 100%;
    }

    .list-item {
      width: 100%;
      background: #ffffff;
      border-radius: 0.24rem;
      padding: 0.28rem;
      box-sizing: border-box;
      margin-bottom: 0.28rem;

      .list-item-top {
        margin-bottom: 0.28rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item-time {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          display: flex;
          align-items: center;

          .item-icon {
            width: 0.34rem;
            height: 0.32rem;
            margin-right: 0.08rem;
          }
        }

        .item-status {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff4800;
        }
      }

      .list-item-body {
        height: 1.26rem;
        display: flex;

        .item-img-box {
          width: 2.04rem;
          height: 1.26rem;
          border-radius: 0.08rem;
          overflow: hidden;
          // background-color: #ccc;
          margin-right: 0.2rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .item-info {
          flex: 1;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          .item-product {
            display: flex;
            justify-content: space-between;
            .product-name {
              width: 100%;
              font-size: 0.24rem;
              font-family: PingFangSC-medium, PingFang SC;
              font-weight: 400;
              color: #000000;
            }

            .product-price {
              display: flex;
              align-items: center;

              .price {
                font-size: 0.24rem;
                font-family: PingFangSC-Regular, PingFang SC;
                color: #333333;
                font-weight: 500;
              }

              .unit {
                font-size: 0.18rem;
                margin-top: 0.04rem;
              }
            }
          }

          .service-box {
            margin-top: 0.14rem;
            height: 0.28rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffa700;

            .product-type {
              height: 0.4rem;
              display: flex;
              align-items: center;

              .type-name {
                background: #f9f9f9;
                border-radius: 0.04rem;
                padding: 0 0.08rem;
                font-size: 0.2rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
              }
            }
          }

          .item-type {
            display: flex;
            justify-content: space-between;
            margin-top: 0.04rem;

            .attr-text {
              font-size: 0.22rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }

            .buynum {
              font-size: 0.24rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
        }
      }

      .item-pricebox {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.28rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;

        .pricebox-right {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
        }

        .pricebox-left,
        .pricebox-center,
        .pricebox-right {
          > span:nth-child(2) {
            font-size: 0.18rem;
            margin-top: 0.04rem;
          }
        }
      }

      .list-item-action {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.24rem;

        .action-btn {
          width: 1.6rem;
          height: 0.56rem;
          border-radius: 0.5rem;
          border: 0.016rem solid #e8e8e8;
          font-size: 0.26rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999;
          text-align: center;
          // line-height: 0.64rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;

          &.pay {
            color: #ff4800;
            border-color: #ff4800;
          }
        }
      }
    }
  }

  .no-list {
    width: 100%;
    height: 100%;
    // background: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-img {
      width: 1.96rem;
      height: 1.96rem;
      border-radius: 50%;
      overflow: hidden;
      background-color: #ccc;
      margin-top: 30%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .no-tip {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 0.32rem;
    }
  }
}
</style>